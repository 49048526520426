import { IUser, IUserInfo } from '/@/type/store/user';
import { accountlogin } from '/@/api/user/index';
import { ILoginParams } from '/@/type/views/login';
import router from '/@/router/index';
import { decodeUrl } from '/@/utils/tools';
import { setStorage, getStorage } from '/@/utils/storage';
const state: IUser = {
	token: getStorage('token') || '',
	userInfo: getStorage('userInfo') || {},
};

const mutations = {
	SET_TOKEN(state: IUser, token: String) {
		state.token = token;
	},
	SET_USER_INFO(state: IUser, data: IUserInfo) {
		state.userInfo = data;
	},
};

const actions = {
	async login({ commit }: any, params: ILoginParams) {
		const { data } = await accountlogin(params);
		const token = data.token;
		commit('SET_TOKEN', token);
		commit('SET_USER_INFO', data);
		setStorage('token', token);
		setStorage('userInfo', data);
		const { query } = router.currentRoute.value;
		router.push(
			typeof query.from === 'string' ? decodeUrl(query.from) : '/platform'
		);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
