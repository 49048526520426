// @ts-nocheck
import request from '/@/utils/request';

export const accountlogin = (params: any) => {
	return request({
		url: '/user/accountlogin/ordinary',
		method: 'post',
		params,
		isLogin: true,
	});
};

export const loginSettoken = (params: any) => {
	return request({
		url: '/user/accountlogin/settoken',
		method: 'post',
		params,
		isLogin: true,
		headers: {
			api_client_code: params.systemCode,
		},
	});
};

export const getUserInfo = (params: any) => {
	return request({
		url: '/user/account/derucci/userInfo',
		method: 'get',
		params,
	});
};

