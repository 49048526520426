export default [
	{
		name: 'menu',
		path: '/system/menu',
		component: () => import('/@/views/system/menu.vue'),
		meta: { title: '菜单管理' },
	},
	{
		name: 'role',
		path: '/system/role',
		component: () => import('/@/views/system/role.vue'),
		meta: { title: '角色管理' },
	},
	{
		name: 'user',
		path: '/system/user',
		component: () => import('/@/views/system/user.vue'),
		meta: { title: '用户管理' },
	},
	{
		name: 'department',
		path: '/system/department',
		component: () => import('/@/views/system/department.vue'),
		meta: { title: '部门管理' },
	},
	{
		name: 'application',
		path: '/system/application',
		component: () => import('/@/views/system/application.vue'),
		meta: { title: '应用管理' },
	},
];
