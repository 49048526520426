// @ts-nocheck
import { store } from '/@/store/index';
import router from '/@/router';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import { removeStorage } from '/@/utils/storage';
import qs from 'qs';
import md5 from 'md5';

// const router = useRouter();

let loading: { close(): void };
// 创建 axios 实例
const request = axios.create({
	// API 请求的默认前缀
	baseURL: import.meta.env.VITE_BASE_URL, //import.meta.env.VITE_BASE_URL as string | undefined,
	timeout: 60000, // 请求超时时间
});

const getSign = (obj) => {
	const str = qs.stringify(obj);
	return md5(qs.stringify(obj)).toUpperCase();
};

// 异常拦截处理器
const errorHandler = (error: { message: string }) => {
	loading.close();
	console.log(`err${error}`);
	ElMessage.error(error.message);
	return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
	loading = ElLoading.service({
		lock: true,
		text: 'Loading',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.4)',
	});

	const requestHeader = {
		api_client_code: '10',
		api_version: '1.0.0',
		api_timestamp: Date.now().toString(),
	};
	const token = store.getters.token;

	if (!config.isLogin) {
		requestHeader.api_sign = getSign({
			api_token: token,
			...requestHeader,
		});
		requestHeader.api_token = token;
	}
	config.headers = {
		...requestHeader,
		...config.headers,
	};

	return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response: AxiosResponse<IResponse>) => {
	const { data } = response;
	loading.close();
	if (data.code !== 0) {
		const codeArr = [101, 102];
		if (codeArr.includes(data.code)) {
			ElMessage.error('登录信息失效了');
			removeStorage('token');
			removeStorage('userInfo');
			store.commit('user/SET_TOKEN', '');
			router.push(`/login?redirect=${location.hash.substr(1)}`);
			location.reload()
			return;
		}
		ElMessage.error(data.msg);
		return Promise.reject(new Error('Error'));
	}
	return data;
}, errorHandler);

export default request;
