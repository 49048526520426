// @ts-nocheck
import router, { allowRouter } from '/@/router';
import { store } from '/@/store/index';
import { configure, start, done } from 'nprogress';
import { RouteRecordRaw } from 'vue-router';
import { decodeUrl, encodeUrl } from '/@/utils/tools';

import { generatorDynamicRouter } from '/@/router/asyncRouter';

import { getStorage, setSession, setStorage } from '/@/utils/storage';
import { loginSettoken, getUserInfo } from '/@/api/user/index';

configure({ showSpinner: false });

const loginRoutePath = '/login';
const defaultRoutePath = '/';

router.beforeEach(async (to, from) => {
	start();
	if (to.query.account) {
		const { data } = await loginSettoken({
			account: to.query.account,
			systemCode: '10',
		});
		await store.commit('user/SET_TOKEN', data.token);

		setStorage('token', data.token);

		const res = await getUserInfo({
			apiToken: data.token,
			systemCode: '10',
		});
		setStorage('userInfo', res.data);
		await store.commit('user/SET_USER_INFO', res.data);
		setSession('systemCode', 30);
		if (to.query.systemCode == 30) {
			setSession('systemName', '渠道运营操作系统OOS');
		}
	}

	const { layout } = store.state;
	const token = store.getters.token;
	// 修改页面title
	// const reg = new RegExp(/^(.+)(\s\|\s.+)$/);
	// const appTitle = import.meta.env.VITE_APP_TITLE;
	// document.title = appTitle.match(reg)
	// 	? appTitle.replace(reg, `${to.meta.title}$2`)
	// 	: `${to.meta.title} | ${appTitle}`;
	// 判断当前是否在登陆页面
	if (to.path.toLocaleLowerCase() === loginRoutePath.toLocaleLowerCase()) {
		done();
		if (token)
			return typeof to.query.from === 'string'
				? decodeUrl(to.query.from)
				: defaultRoutePath;
		return;
	}
	// 判断是否登录
	if (!token)
		return (
			loginRoutePath + (to.fullPath ? `?from=${encodeUrl(to.fullPath)}` : '')
		);
	// 判断是否还没添加过路由
	if (layout.menubar.menuList.length === 0) {
		// const treeList = treeToArray(store.getters.userInfo.menuList);
		// const menuList = treeList.map(({ name, path, id, um_parent_id, type }) => ({
		// 	name: path,
		// 	path,
		// 	id,
		// 	parentId: um_parent_id,
		// 	redirect: '',
		// 	component: 'Layout',
		// 	meta: { title: name, type },
		// }));
		// // await store.dispatch('layout/GenerateRoutes');
		// // await store.dispatch('layout/getUser');
		// generatorDynamicRouter(menuList);
		// console.log(layout.menubar.menuList);
		// for (let i = 0; i < layout.menubar.menuList.length; i++) {
		// 	router.addRoute(layout.menubar.menuList[i] as RouteRecordRaw);
		// }
		// store.commit('layout/concatAllowRoutes');
		// return to.fullPath;
	}
	await store.commit('layout/setRoutes', getStorage('userInfo')['menuList']);
	store.commit('layout/changeTagNavList', to); // 切换导航，记录打开的导航(标签页)

	// 离开当前页面时是否需要添加当前页面缓存
	!new RegExp(/^\/redirect\//).test(from.path) &&
		store.state.layout.tags.tagsList.some((v) => v.name === from.name) &&
		!store.state.layout.tags.cachedViews.some((v) => v === from.name) &&
		store.commit('layout/addCachedViews', {
			name: from.name,
			noCache: from.meta.noCache,
		});
});

router.afterEach(() => {
	done();
});
