import { createApp } from "vue";
import App from "/@/App.vue";
import ElementPlus from "element-plus";
import direct from "/@/directive/index";
import router from "/@/router/index";
import { store } from "/@/store/index";
import "/@/permission";

import "element-plus/lib/theme-chalk/index.css";
import "element-plus/lib/theme-chalk/display.css";

// import 'element-plus/dist/index.css'

import "nprogress/nprogress.css";
import "/@/assets/css/index.css";

const sensors = (window as any)["sensorsDataAnalytic201505"];
sensors.init({
  server_url: import.meta.env.VITE_SENSORS_URL,
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: "beacon",
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: "default",
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: "default",
  },
  latest_utm: false,
  show_log: false,
});
sensors.registerPage({ platform_type: "web", app_name: "中控管理平台" });
sensors.quick("autoTrack");

const app = createApp(App);
direct(app);
app.use(ElementPlus, { size: "small" });
app.use(router);
app.use(store);
app.mount("#app");
