import { createStore, Store, useStore as baseUseStore } from 'vuex';
import layout from '/@/store/module/layout';
import user from '/@/store/module/user';
import getters from './gettters';
// import { loadStorePage } from '/@/utils/tools'
// const test = import.meta.globEager('./test/**/**.ts')
// ...loadStorePage(test)
export const store = createStore<IState>({
	modules: {
		layout,
		user,
	},
	getters,
});

export function useStore(): Store<IState> {
	return baseUseStore();
}
