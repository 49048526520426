<template>
  <el-config-provider :locale="locale" size="small">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import locale from "element-plus/lib/locale/lang/zh-cn";
import { useStore } from "/@/store/index";

export default defineComponent({
  name: "App",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    return {
      locale,
    };
  },
  mounted() {
    const store = useStore();
    store.commit("layout/changeTheme", 0);
  },
});
</script>
